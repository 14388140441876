import React from 'react'
import PropTypes from 'prop-types'
import {graphql} from 'gatsby'

import Layout from '../components/Layout'
import useSiteMetadata from "../components/SiteMetadata";
import {Helmet} from "react-helmet";

export const IndexPageTemplate =
    ({title, heading, subheading, mainpitch, description}) => (
        <div className="osi-contents">
            <div className="osi-eyecatch">
                <div className="content">
                    <h1 className="title is-3 is-spaced">{title}</h1>
                    <p className="subtitle is-4 is-spaced">{subheading}</p>
                </div>
            </div>
            <div className="osi-maincontents">
                <div className="content mainpitch">
                    <h3 className="title is-4 is-spaced">{mainpitch.title}</h3>
                    <p className="description">{mainpitch.description}</p>
                </div>
                <div className="content">
                    <h3 className="title is-4 is-spaced">{heading}</h3>
                    <p className="description">{description}</p>
                </div>
            </div>
        </div>

    )

IndexPageTemplate.propTypes = {
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    title: PropTypes.string,
    heading: PropTypes.string,
    subheading: PropTypes.string,
    mainpitch: PropTypes.object,
    description: PropTypes.string,
}

const IndexPage = ({data}) => {
    const {frontmatter} = data.markdownRemark

    return (
        <Layout page="index">
            <IndexPageTemplate
                image={frontmatter.image}
                title={frontmatter.title}
                heading={frontmatter.heading}
                subheading={frontmatter.subheading}
                mainpitch={frontmatter.mainpitch}
                description={frontmatter.description}
            />
        </Layout>
    )
}

IndexPage.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            frontmatter: PropTypes.object,
        }),
    }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
      }
    }
  }
`
